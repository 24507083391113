<template>
  <v-container
    ><v-row>
      <!-- Pantalla -->
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <PageHeader :title="title" class="pb-3" />
        <v-card width="100%" outlined tile id="tabsCardPadronMensual">
          <!-- Tabs -->

          <v-tabs
            id="tabsPadronMensualSss"
            v-model="tab"
            active-class="active"
            center-active
            height="2.5rem"
            hide-slider
          >
            <v-tab href="#parentescos"
              >Parentescos SSS
              <v-icon small class="primary--text" v-if="tab != 'parentescos'">{{
                openTabIcon
              }}</v-icon>
            </v-tab>
            <v-tab href="#tipos-documento"
              >Tipos de documento
              <v-icon
                small
                class="primary--text"
                v-if="tab != 'tipos-documento'"
                >{{ openTabIcon }}</v-icon
              >
            </v-tab>

            <v-tab href="#estados-civiles"
              >Estados civiles
              <v-icon
                small
                class="primary--text"
                v-if="tab != 'estados-civiles'"
                >{{ openTabIcon }}</v-icon
              >
            </v-tab>
            <v-tab href="#tipos-beneficiarios"
              >Tipos de beneficiarios
              <v-icon
                small
                class="primary--text"
                v-if="tab != 'tipos-beneficiarios'"
                >{{ openTabIcon }}</v-icon
              >
            </v-tab>
            <v-tab href="#localidades"
              >Localidades
              <v-icon small class="primary--text" v-if="tab != 'localidades'">{{
                openTabIcon
              }}</v-icon>
            </v-tab>
            <v-tab href="#provincias"
              >Provincias
              <v-icon small class="primary--text" v-if="tab != 'provincias'">{{
                openTabIcon
              }}</v-icon>
            </v-tab>
            <v-tab href="#nacionalidades"
              >Nacionalidades
              <v-icon
                small
                class="primary--text"
                v-if="tab != 'nacionalidades'"
                >{{ openTabIcon }}</v-icon
              >
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- Tipos de documento -->

            <v-tab-item value="tipos-documento">
              <v-container class="d-flex align-center justify-center">
                <v-card flat width="25rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="tiposDocumentoHeaders"
                    :items="tiposDocumento"
                    :items-per-page="5"
                    item-key="tdId"
                    :search="searchTiposDoc"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchTiposDoc"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right pt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text "
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span
                                >Editar el código SSS en tipos de
                                documento</span
                              >
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditCodigoSSS(
                                item.tdId,
                                item.tdNombre,
                                item.sssCodigo,
                                tab,
                                tiposDocumento
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar código SSS</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              >
            </v-tab-item>

            <!-- Nacionalidades -->

            <v-tab-item value="nacionalidades"
              ><v-container class="d-flex align-center justify-center">
                <v-card flat width="25rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="nacionalidadesHeaders"
                    :items="nacionalidades"
                    :items-per-page="5"
                    :search="searchNacionalidades"
                    item-key="nacId"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchNacionalidades"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right pt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text"
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span
                                >Editar el código SSS en nacionalidades</span
                              >
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditCodigoSSS(
                                item.nacId,
                                item.nacNombre,
                                item.sssCodigo,
                                tab,
                                nacionalidades
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar código SSS</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              ></v-tab-item
            >
            <!-- Provincias -->

            <v-tab-item value="provincias"
              ><v-container class="d-flex align-center justify-center">
                <v-card flat width="35rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="provinciasHeaders"
                    :items="provincias"
                    :items-per-page="5"
                    :search="searchProvincias"
                    item-key="provId"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchProvincias"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right pt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text"
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span>Editar el código SSS en provincias</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditCodigoSSS(
                                item.provId,
                                item.provNombre,
                                item.sssCodigo,
                                tab,
                                provincias
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar código SSS</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              ></v-tab-item
            >
            <!-- Localidades -->

            <v-tab-item value="localidades"
              ><v-container class="d-flex align-center justify-center">
                <v-card flat width="45rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="localidadesHeaders"
                    :items="localidades"
                    :items-per-page="5"
                    :search="searchLocalidades"
                    item-key="locId"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchLocalidades"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right pt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text"
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span>Editar el código SSS en localidades</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditCodigoSSS(
                                item.locId,
                                item.locNombre,
                                item.sssCodigo,
                                tab,
                                localidades
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar código SSS</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              ></v-tab-item
            >

            <!-- Estados civiles -->

            <v-tab-item value="estados-civiles">
              <v-container class="d-flex align-center justify-center"
                ><v-card width="25rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="estadosCivilesHeaders"
                    :items-per-page="5"
                    :items="estadosCiviles"
                    :search="searchEstadosCiviles"
                    item-key="estcivilId"
                    ><template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditCodigoSSS(
                                item.estcivilId,
                                item.estcivilNombre,
                                item.sssCodigo,
                                tab,
                                estadosCiviles
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar código SSS</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchEstadosCiviles"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right pt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text"
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span
                                >Editar el código SSS en estados civiles</span
                              >
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-card></v-container
              ></v-tab-item
            >

            <!-- Tipos beneficiarios -->

            <v-tab-item value="tipos-beneficiarios">
              <v-container class="d-flex align-center justify-center">
                <v-card max-width="45rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="tiposBeneficiariosHeaders"
                    :items="tiposBeneficiarios"
                    :items-per-page="5"
                    item-key="tipoBenId"
                    :search="searchTiposBeneficiarios"
                    ><template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditTiposBeneficiarios(
                                item,
                                tiposBeneficiarios
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar tipo de beneficiario</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchTiposBeneficiarios"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right pt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text"
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span
                                >Configurar tipos beneficiarios para SSS</span
                              >
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template
                      v-slot:[`item.cuilTitularEnCuitempleador`]="{ item }"
                    >
                      <v-icon
                        v-if="item.cuilTitularEnCuitempleador"
                        small
                        class="primary--text"
                        >{{ checkIcon }}</v-icon
                      >
                    </template>
                    <template v-slot:[`item.tipoBenNoIncluirPadron`]="{ item }">
                      <v-icon
                        v-if="item.tipoBenNoIncluirPadron"
                        small
                        class="primary--text"
                        >{{ checkIcon }}</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-container>
            </v-tab-item>

            <!-- Parentescos -->

            <v-tab-item value="parentescos">
              <v-container class="d-flex align-center justify-center"
                ><v-card flat width="70rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="parentescosHeaders"
                    :items-per-page="5"
                    :items="parentescos"
                    :search="searchParentescos || searchParentescosCombo"
                    item-key="sssParId"
                    ><template v-slot:[`item.incapacidad`]="{ item }">
                      <v-icon
                        v-if="item.incapacidad"
                        small
                        class="primary--text"
                        >{{ checkIcon }}</v-icon
                      >
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="4" align-self="center">
                            <v-text-field
                              v-model="searchParentescos"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" align-self="center">
                            <v-autocomplete
                              v-model="searchParentescosCombo"
                              item-text="value"
                              item-value="value"
                              clearable
                              :items="parentescosCombo"
                              label="Buscar por parentesco"
                              single-line
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2" class="text-center pt-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="primary--text"
                                  v-on="on"
                                  v-bind="attrs"
                                  >{{ infoIcon }}</v-icon
                                >
                              </template>
                              <span>Configurar parentescos SSS</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="2" class="text-right">
                            <v-btn
                              class="primary"
                              @click="
                                toggleModalEditParentesco(null, parentescos)
                              "
                              >Nuevo</v-btn
                            ></v-col
                          >
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="1.2rem"
                            v-on="on"
                            @click="
                              toggleModalEditParentesco(
                                item.sssParId,
                                parentescos
                              )
                            "
                            class="p-1"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar parentesco</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            size="1.2rem"
                            @click="deleteParentesco(item.sssParId)"
                            class="p-1"
                          >
                            {{ deleteIcon }}
                          </v-icon>
                        </template>
                        <span>Eliminar parentesco</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              ></v-tab-item
            >
          </v-tabs-items>
        </v-card>
      </v-col>

      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-1 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
        />
      </v-col>
    </v-row>
    <!-- Editar codigos SSS -->
    <v-dialog
      v-if="modalEditCodigoSSS"
      v-model="modalEditCodigoSSS"
      @keydown.esc="toggleModalEditCodigoSSS"
      max-width="30rem"
      persistent
    >
      <EditCodigoSSS
        :idItemToEdit="idItemToEdit"
        :fieldNameToEdit="fieldNameToEdit"
        :codigoSSS="codigoSSS"
        :tabToEdit="tabToEdit"
        :arrayToCheckCodigos="arrayToCheckCodigos"
        @toggleModalEditCodigoSSS="toggleModalEditCodigoSSS"
        @closeModalsAndSetTables="closeModalsAndSetTables"
      ></EditCodigoSSS>
    </v-dialog>
    <!-- Editar parentesco -->
    <v-dialog
      v-if="modalEditParentesco"
      v-model="modalEditParentesco"
      @keydown.esc="toggleModalEditParentesco"
      max-width="35rem"
      persistent
    >
      <EditParentescoPadronMensual
        :idParentescoToEdit="idParentescoToEdit"
        :arrayToCheckCodigos="arrayToCheckCodigos"
        @toggleModalEditParentesco="toggleModalEditParentesco"
        @closeModalsAndSetTables="closeModalsAndSetTables"
      ></EditParentescoPadronMensual>
    </v-dialog>
    <!-- Editar tipos beneficiarios -->
    <v-dialog
      v-if="modalEditTipoBeneficiario"
      v-model="modalEditTipoBeneficiario"
      @keydown.esc="toggleModalEditTiposBeneficiarios"
      max-width="30rem"
      persistent
    >
      <EditTiposBeneficiarios
        :tipoBeneficiarioObject="itemTipoBeneficiario"
        :arrayToCheckCodigos="arrayToCheckCodigos"
        @toggleModalEditTiposBeneficiarios="toggleModalEditTiposBeneficiarios"
        @closeModalsAndSetTables="closeModalsAndSetTables"
      ></EditTiposBeneficiarios>
    </v-dialog>
    <!-- Eliminar parentesco -->
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDeleteParentesco()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import axios from "@/axios/axios-api";
import enums from "@/utils/enums/index.js";
import EditCodigoSSS from "@/components/modules/afiliaciones/afiliados/configuracion/PadronMensualSSS/EditCodigoSSS";
import EditParentescoPadronMensual from "@/components/modules/afiliaciones/afiliados/configuracion/PadronMensualSSS/EditParentescoPadronMensual.vue";
import EditTiposBeneficiarios from "@/components/modules/afiliaciones/afiliados/configuracion/PadronMensualSSS/EditTiposBeneficiarios.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "PadronMensualSSS",
  components: {
    PageHeader,
    EditCodigoSSS,
    EditParentescoPadronMensual,
    DeleteDialog,
    EditTiposBeneficiarios,
    Ayuda
  },

  data() {
    return {
      title: enums.titles.PADRON_MENSUAL_SSS,
      optionCode: enums.webSiteOptions.PADRON_MENSUAL_SSS,
      showHelp: false,
      showExpand: false,
      tab: null,
      isAyudaSistema: true,
      modalEditCodigoSSS: false,
      modalEditParentesco: false,
      modalEditTipoBeneficiario: false,
      modalTextoAyuda: false,
      itemTipoBeneficiario: {},
      idItemToEdit: null,
      idParentescoToEdit: null,
      fieldNameToEdit: null,
      codigoSSS: null,
      tabToEdit: "",
      arrayToCheckCodigos: [],
      editIcon: enums.icons.EDIT,
      infoIcon: enums.icons.SNB_INFO,
      helpIcon: enums.icons.HELP,
      closeIcon: enums.icons.CLOSE_WINDOW,
      openTabIcon: enums.icons.DOWN_ARROW,

      titleDelete: "¿Eliminar parentesco?",
      idToDelete: null,
      showDeleteModal: false,
      deleteIcon: enums.icons.DELETE,
      checkIcon: enums.icons.CHECK_OUTLINE,
      searchIcon: enums.icons.SEARCH,
      searchNacionalidades: null,
      searchTiposBeneficiarios: null,
      searchParentescos: null,
      searchParentescosCombo: null,
      searchEstadosCiviles: null,
      searchProvincias: null,
      searchLocalidades: null,
      searchTiposDoc: null,
      tiposDocumento: [],
      nacionalidades: [],
      estadosCiviles: [],
      tiposBeneficiarios: [],
      parentescos: [],
      localidades: [],
      provincias: [],
      parentescosCombo: [],
      tiposDocumentoHeaders: [
        {
          text: "Tipo de documento",
          align: "start",
          sortable: true,
          value: "tdNombre"
        },
        {
          text: "Código SSS",
          sortable: false,
          align: "center",
          value: "sssCodigo"
        },
        {
          text: "Editar código SSS",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      nacionalidadesHeaders: [
        {
          text: "Nacionalidad",
          align: "start",
          sortable: true,
          value: "nacNombre"
        },
        {
          text: "Código SSS",
          sortable: false,
          align: "center",
          value: "sssCodigo"
        },
        {
          text: "Editar código SSS",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      localidadesHeaders: [
        {
          text: "Localidad",
          align: "start",
          sortable: true,
          value: "locNombre"
        },
        {
          text: "Código SSS",
          sortable: false,
          align: "center",
          value: "sssCodigo"
        },
        {
          text: "Código postal",
          sortable: false,
          align: "center",
          value: "codPostal"
        },
        {
          text: "Provincia",
          sortable: false,
          align: "start",
          value: "provNom"
        },
        {
          text: "Editar código SSS",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      provinciasHeaders: [
        {
          text: "Provincia",
          align: "start",
          sortable: true,
          value: "provNombre"
        },
        {
          text: "País",
          align: "start",
          sortable: true,
          value: "paisNom"
        },
        {
          text: "Código SSS",
          sortable: false,
          align: "center",
          value: "sssCodigo"
        },
        {
          text: "Editar código SSS",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      estadosCivilesHeaders: [
        {
          text: "Estados civiles",
          align: "start",
          sortable: true,
          value: "estcivilNombre"
        },
        {
          text: "Código SSS",
          sortable: false,
          align: "center",
          value: "sssCodigo"
        },
        {
          text: "Editar código SSS",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      tiposBeneficiariosHeaders: [
        {
          text: "Tipos de beneficiarios",
          align: "start",
          sortable: true,
          value: "tipoBenNom"
        },
        {
          text: "Código SSS",
          sortable: false,
          align: "center",
          value: "tipoBenCod"
        },
        {
          text: "Forzar CUIL del titular en campo CUIT",
          sortable: false,
          align: "center",
          value: "cuilTitularEnCuitempleador"
        },
        {
          text: "Excluir del padrón mensual a la SSS",
          sortable: false,
          align: "center",
          value: "tipoBenNoIncluirPadron"
        },
        {
          text: "Forzar el siguiente CUIT",
          sortable: false,
          align: "center",
          value: "forzarCuitExpSss"
        },
        { text: "Editar", value: "actions", sortable: false, align: "end" }
      ],
      parentescosHeaders: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "sssParNombre"
        },
        { text: "Código SSS", sortable: false, value: "sssParCod" },
        { text: "Edad desde", sortable: false, value: "edadDesde" },
        { text: "Edad hasta", sortable: false, value: "edadHasta" },
        {
          text: "Incapacitado",
          sortable: false,
          align: "center",
          value: "incapacidad"
        },
        { text: "Parentesco", sortable: false, value: "parNom" },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ]
    };
  },
  created() {
    this.setDataTables();
  },
  methods: {
    ...mapActions({
      getTiposBeneficiarios: "configAfiliaciones/getTiposBeneficiarios",
      getNacionalidades: "configAfiliaciones/getNacionalidades",
      getEstadosCiviles: "configAfiliaciones/getEstadosCiviles",
      getTiposDocumentos: "configAfiliaciones/getTiposDocumentos",
      getParentescosSSS: "configAfiliaciones/getParentescosSSS",
      deleteParentescosSSS: "configAfiliaciones/deleteParentescosSSS",
      getLocalidades: "configAfiliaciones/getLocalidades",
      getProvincias: "configAfiliaciones/getProvincias",
      setAlert: "user/setAlert",
      getParentescos: "configAppBenef/getParentescos"
    }),
    async setDataTables() {
      await axios
        .all([
          this.getTiposBeneficiarios(),
          this.getNacionalidades(),
          this.getEstadosCiviles(),
          this.getTiposDocumentos(),
          this.getParentescosSSS(),
          this.getParentescos(),
          this.getLocalidades(),
          this.getProvincias()
        ])
        .then(
          axios.spread((...responses) => {
            this.tiposBeneficiarios = responses[0];
            this.nacionalidades = responses[1];
            this.estadosCiviles = responses[2];
            this.tiposDocumento = responses[3];
            this.parentescos = responses[4];
            this.parentescosCombo = responses[5];
            this.localidades = responses[6];
            this.provincias = responses[7];
          })
        );
    },
    async closeModalsAndSetTables() {
      this.setDataTables();
      this.modalEditCodigoSSS = false;
      this.modalEditParentesco = false;
      this.modalEditTipoBeneficiario = false;
    },
    toggleModalEditCodigoSSS(id, name, codigo, tab, array) {
      this.modalEditCodigoSSS = !this.modalEditCodigoSSS;
      this.idItemToEdit = id;
      this.fieldNameToEdit = name;
      this.codigoSSS = codigo;
      this.tabToEdit = tab;
      this.arrayToCheckCodigos = array;
    },
    toggleModalEditParentesco(id, array) {
      this.idParentescoToEdit = id;
      this.arrayToCheckCodigos = array;
      this.modalEditParentesco = !this.modalEditParentesco;
    },
    toggleModalEditTiposBeneficiarios(itemTipoBeneficiario, array) {
      this.itemTipoBeneficiario = itemTipoBeneficiario;
      this.arrayToCheckCodigos = array;
      this.modalEditTipoBeneficiario = !this.modalEditTipoBeneficiario;
    },
    deleteParentesco(id) {
      (this.idToDelete = id), (this.showDeleteModal = true);
    },
    async confirmDeleteParentesco() {
      const response = await this.deleteParentescosSSS(this.idToDelete);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.parentescos = await this.getParentescosSSS();
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    }
  }
};
</script>

<style scoped>
.active {
  color: #f8f9fa !important;
  background-color: #195472 !important;
}
#tabsCardPadronMensual .active {
  border-radius: 0.2rem !important;
}
.scroll {
  overflow-y: scroll;
}
.tabsPadronMensualSss {
  border-radius: 0.2rem !important;
}
</style>
